import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { ExcelService } from '../../../base/services/excel/excel.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { Util } from 'src/app/utils/utils';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { VentaComponent } from '../../../base/components/venta/venta.component';
import { VentaServicioComponent } from '../../../neighborhood/components/venta-servicio/venta-servicio.component';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { PagoVentaComponent } from '../pago-venta/pago-venta.component';
import { ListaPagosVentasComponent } from '../lista-pagos-ventas/lista-pagos-ventas.component';
import { VistaVentaComponent } from '../vista-venta/vista-venta.component';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { CotizacionesService } from '../../services/cotizaciones/cotizaciones.service';
import { ConfiguracionAplicacionVentasComponent } from 'src/app/base/components/configuracion-aplicacion-ventas/configuracion-aplicacion-ventas.component';
import { VentaEstudianteComponent } from 'src/app/institute/components/venta-estudiante/venta-estudiante.component';
import { ReportePagosInstitutoComponent } from '../reporte-pagos-instituto/reporte-pagos-instituto.component';
import { Socket } from 'ngx-socket-io';
import { ListaDevolucionesVentasComponent } from '../lista-devoluciones-ventas/lista-devoluciones-ventas.component';
import { SinListaEventosSignificativosComponent } from '../sin-lista-eventos-significativos/sin-lista-eventos-significativos.component';
import { MensajesEmpresaService } from 'src/app/marketing/services/mensajes-empresa/mensajes-empresa.service';
import { ParticipantesGruposWhatsappComponent } from 'src/app/base/components/participantes-grupos-whatsapp/participantes-grupos-whatsapp.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css']
})
export class VentasComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  ventas:any[]=[];
  cotizaciones:any[]=[];
  estados:any[]=[];
  venta:any;
  devolucion_venta:any;
  global_variable=GlobalVariable;
  popupConfirmacion:NgbModalRef;
  modalVenta:NgbModalRef;

  ventas_borrador:any[]=[];
  ventas_borrador_modal:NgbModalRef;
  @ViewChild('ventas_borrador_modal')
  private ventas_borrador_ref: TemplateRef<any>;

  lista_cotizaciones_modal:NgbModalRef;
  @ViewChild('lista_cotizaciones_modal')
  private lista_cotizaciones_modal_ref: TemplateRef<any>;

  configuracion_aplicacion_modal:NgbModalRef;

  importacion_ventas_modal:NgbModalRef;
  @ViewChild('importacion_ventas_modal')
  private importacion_ventas_ref: TemplateRef<any>;

  importacion_pagos_ventas_modal:NgbModalRef;
  @ViewChild('importacion_pagos_ventas_modal')
  private importacion_pagos_ventas_modal_ref: TemplateRef<any>;

  conversion_venta_credito_modal:NgbModalRef;
  @ViewChild('conversion_venta_credito_modal')
  private conversion_venta_credito_ref: TemplateRef<any>;

  anulacion_factura_cuf_modal:NgbModalRef;
  @ViewChild('anulacion_factura_cuf_modal')
  private anulacion_factura_cuf_modal_ref: TemplateRef<any>;

  reporte_pagos_instituto:NgbModalRef;

  datos_con_detalle:boolean=false;
  importacion:any={};
  mensaje_importacion:any;
  ventas_importacion:any[]=[];
  pagos_ventas_importacion:any[]=[];
  almacenes:any[];
  anulacion_cuf:any;
  sin_motivos_anulacion:any=[];

  participantes_grupo_whatsapp_modal:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
	public excelService:ExcelService,
	public pdfService:PdfService,
    public ventasService:VentasService,
    public modalService: NgbModal,
	private toastr: ToastrService,
	private pedidoService:PedidosService,
	private cotizacionesService:CotizacionesService,
	private socket: Socket,
	private mensajesEmpresaService:MensajesEmpresaService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

	async ngOnInit(): Promise<void> {
		this.init();
		this.buscarAplicacion(this.router.url.substring(1));

		let fecha_actual=new Date();
		this.filter={
			id_empresa:this.usuario.id_empresa,
			ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
			sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
			usuarios:[{nombre_usuario:"TODOS",id:0}],
			usuario: {nombre_usuario:"TODOS",id:0},
			tipos_pago:[GlobalVariable.Dictionary.SELECCION_TODOS],
			tipo_pago: GlobalVariable.Dictionary.SELECCION_TODOS,
			transacciones:[GlobalVariable.Dictionary.SELECCION_TODOS],
			transaccion: GlobalVariable.Dictionary.SELECCION_TODOS,
			canales:[GlobalVariable.Dictionary.SELECCION_TODOS],
			canal: GlobalVariable.Dictionary.SELECCION_TODOS,
			despachos:[GlobalVariable.Dictionary.SELECCION_TODOS],
			despacho: GlobalVariable.Dictionary.SELECCION_TODOS,
			activas:[GlobalVariable.Dictionary.SELECCION_TODOS,{id:1,nombre:"ACTIVAS"},{id:2,nombre:"ANULADAS"}],
			activa: GlobalVariable.Dictionary.SELECCION_TODOS,
			fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			con_detalle:false
		}
		await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_VENTAS);

		let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
		}

		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}
		
		this.obtenerTiposDePago();
		this.obtenerMovimientosEgreso();
		await this.obtenerCanales();

		if(this.usuario.empresa.usar_pedidos_restaurante || this.usuario.empresa.usar_pedidos_en_linea){
			this.obtenerTiposDespachos();
		}
		
		this.column = "fecha";
		this.direction = "desc";
		this.itemsPerPage=0;
	}

  ngAfterViewInit() {
		//aplicarScriptPaginaProductos();	
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.obtenerTextosEmpresa();
	}

	async obtenerCanales(){
		let entidad:any=await this.generalService.obtenerClases("SNAPCANVEN").toPromise();
		let canales=entidad.clases;
		if(this.usuario.empresa.usar_permisos_avanzados_usuarios){
			canales=this.configuracion_pagina.object.datos.opciones_permisos.canales_asignados.length==0?canales:[];
			for(let i=0;i<this.configuracion_pagina.object.datos.opciones_permisos.canales_asignados.length;i++){
				canales.push(entidad.clases.filter(cnf => cnf.id==this.configuracion_pagina.object.datos.opciones_permisos.canales_asignados[i])[0]);
			}
			let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.filter.canales=es_usuario_administrador?this.filter.canales.concat(canales):canales;
			this.filter.canal=this.filter.canales[0];
		}else{
			this.filter.canales=this.filter.canales.concat(canales);
		}
	}

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }
  
  obtenerTiposDePago(){
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.filter.tipos_pago=this.filter.tipos_pago.concat(entidad.clases);
    });
  }	
  
  obtenerMovimientosEgreso(){
    this.generalService.obtenerClases("MOVEGR").subscribe((entidad:any) => {
      let movimientos_egreso_original=entidad.clases;
			let movimientos_egreso=movimientos_egreso_original.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
			if(this.usuario.empresa.usar_facturacion){
				movimientos_egreso=movimientos_egreso.concat(movimientos_egreso_original.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
			}
			if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
				movimientos_egreso=movimientos_egreso.concat(movimientos_egreso_original.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA));
			}
			if(this.usuario.empresa.usar_facturacion_manual){
				movimientos_egreso=movimientos_egreso.concat(movimientos_egreso_original.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL));
			}
      this.filter.transacciones=this.filter.transacciones.concat(movimientos_egreso);
    });
	}

	async obtenerTiposDespachos(){
		if(this.usuario.empresa.usar_pedidos_en_linea){
			this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"REST_DESP").subscribe((entidad:any) => {
				this.filter.despachos=this.filter.despachos.concat(entidad.clases);
			  });
		  }else if(this.usuario.empresa.usar_clientes_contactos){
			let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
			this.filter.despachos=this.filter.despachos.concat(entidad.clases);
		  }else{
			this.generalService.obtenerClases("REST_DESP").subscribe((entidad:any) => {
				this.filter.despachos=this.filter.despachos.concat(entidad.clases);
			  });
		  }
	}
  
  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.ventasService.obtenerListaVentas(this).subscribe((dato:any) => {
			this.setPages(1);
			if(this.filter.con_detalle){
				this.ventas=this.procesarVentas(dato.ventas);
			}else{
				this.ventas=dato.ventas;
			}
			this.blockUI.stop();
		});
  }

	procesarVentas(ventas){
		let ventas_procesadas=[];
		//this.items_resumen=[];
		//var id_clientes=$filter('groupBy')(clientes,'id_cliente');

		let id_ventas = ventas.filter((venta, i, arr) => arr.findIndex(t => t.id === venta.id) === i);

		for(let i=0;i<id_ventas.length;i++){
			let items=ventas.filter(e => e.id == id_ventas[i].id);
			let venta=items[0];
			ventas_procesadas.push(venta);
		}

		for(var i=0;i<ventas_procesadas.length;i++){
			ventas_procesadas[i].detallesVenta=ventas.filter(v => v.id==ventas_procesadas[i].id);
		}
		return ventas_procesadas;
	}
  
  sumarImporte(){
		var suma=0;
		for(var i=0;i<this.ventas.length;i++){
			if(this.ventas[i].activa){
				suma=suma+this.ventas[i].importe;
			}
		}
		return Math.round(suma*100)/100;
	}

	sumarDescuento(){
		var suma=0;
		for(var i=0;i<this.ventas.length;i++){
			if(this.ventas[i].activa){
				suma=suma+this.ventas[i].descuento;
			}
		}
		return Math.round(suma*100)/100;
	}

	sumarTotal(){
		var suma=0;
		for(var i=0;i<this.ventas.length;i++){
			if(this.ventas[i].activa){
				suma=suma+this.ventas[i].total;
			}
		}
		return Math.round(suma*100)/100;
	}

	sumarAcuenta(){
		var suma=0;
		for(var i=0;i<this.ventas.length;i++){
			if(this.ventas[i].activa){
				suma=suma+(this.ventas[i].tipo_pago_nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE?this.ventas[i].a_cuenta:this.ventas[i].total);
			}
		}
		return Math.round(suma*100)/100;
  }

  sumarSaldo(){
	var suma=0;
	for(var i=0;i<this.ventas.length;i++){
		if(this.ventas[i].activa){
			suma=suma+(this.ventas[i].tipo_pago_nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE?this.ventas[i].saldo:0);
		}
	}
	return Math.round(suma*100)/100;
	}
  
  calcularDiasMora(venta){
		var fecha_venta=new Date(venta.fecha);
		var fecha_actual=new Date();
		var diferencia_tiempo = Math.abs(fecha_actual.getTime() - fecha_venta.getTime());
		var diferencia_dias = Math.ceil(diferencia_tiempo / (1000 * 3600 * 24)); 
		var diferencia=venta.dias_credito-diferencia_dias;
		if(diferencia>0){
			return 0;
		}else{
			return Math.abs(diferencia);
		}
	}

	crearNuevaVenta(){
		this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalVenta.componentInstance.venta.usuario = this.usuario;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
		this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.venta.sucursal = this.configuracion_pagina.getOrden().sucursal_defecto?this.configuracion_pagina.getOrden().sucursal_defecto:null;
		this.modalVenta.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;	
		this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
		this.modalVenta.componentInstance.nombre_corto_tipo_pago=this.configuracion_pagina.getOrden().tipo_pago_por_defecto;
		this.modalVenta.componentInstance.nombre_corto_transaccion=this.configuracion_pagina.getOrden().transaccion_por_defecto;
		this.modalVenta.componentInstance.cantidad_decimal=this.configuracion_pagina.getOrden().cantidad_decimal;
		
		this.modalVenta.componentInstance.alTerminar.subscribe(async (res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.stack.substring(0,500));
					this.toastr.error(res.mensaje);
				}else{
					this.toastr.success(res.mensaje);
					let pdf=await this.imprimirVenta(res.venta,true);
					if(res.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || res.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL){
						res.venta.detallesVenta=[];
						await this.enviarCorreoElectronico(res.venta,pdf);
					}
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
		});
		
		if(this.usuario.empresa.usar_pantalla_cliente){
			//socket.emit('comenzarVenta',$rootScope.venta);
		}
	}

	async imprimirVenta(venta,es_guardar){
		let pdf_respuesta;
		if(es_guardar){
			if(this.configuracion_pagina.getOrden().impresion.al_guardar){
				pdf_respuesta=await this.pdfService.generarPdfDocumentoVenta(venta);
				var w = window.open(window.URL.createObjectURL(pdf_respuesta), '_blank', 'location=no');
				setTimeout(function () {w.print();}, 500);
				if(this.usuario.empresa.usar_inventario && this.configuracion_pagina.getOrden().impresion.nota_almacen){
					let ventaConsultada:any=await this.ventasService.obtenerVenta(venta.id).toPromise();
					ventaConsultada.fecha=new Date(ventaConsultada.fecha);
					ventaConsultada.fechaTexto=ventaConsultada.fecha.getDate()+"/"+(ventaConsultada.fecha.getMonth()+1)+"/"+ventaConsultada.fecha.getFullYear();
					this.pdfService.imprimirNotaAlmacenCartaOficio(ventaConsultada,this.configuracion_pagina.getOrden().impresion,this.usuario);
				}
			}
		}else{
			pdf_respuesta=await this.pdfService.generarPdfDocumentoVenta(venta);
			var w = window.open(window.URL.createObjectURL(pdf_respuesta), '_blank', 'location=no');
			setTimeout(function () {w.print();}, 500);
			if(this.usuario.empresa.usar_inventario && this.configuracion_pagina.getOrden().impresion.nota_almacen){
				let ventaConsultada:any=await this.ventasService.obtenerVenta(venta.id).toPromise();
				ventaConsultada.fecha=new Date(ventaConsultada.fecha);
				ventaConsultada.fechaTexto=ventaConsultada.fecha.getDate()+"/"+(ventaConsultada.fecha.getMonth()+1)+"/"+ventaConsultada.fecha.getFullYear();
				this.pdfService.imprimirNotaAlmacenCartaOficio(ventaConsultada,this.configuracion_pagina.getOrden().impresion,this.usuario);
			}
		}
		return pdf_respuesta;

		/*this.ventasService.obtenerVenta(venta.id).subscribe((ventaConsultada:any) => {
			ventaConsultada.fecha=new Date(ventaConsultada.fecha);
			ventaConsultada.fechaTexto=ventaConsultada.fecha.getDate()+"/"+(ventaConsultada.fecha.getMonth()+1)+"/"+ventaConsultada.fecha.getFullYear();
			if(es_guardar){
				if(this.configuracion_pagina.getOrden().impresion.al_guardar){
					this.pdfService.imprimirVenta(ventaConsultada,this.configuracion_pagina.getOrden().impresion,false,this.usuario,this.tipo_textos);
					if(this.usuario.empresa.usar_inventario && this.configuracion_pagina.getOrden().impresion.nota_almacen){
						this.pdfService.imprimirNotaAlmacenCartaOficio(ventaConsultada,this.configuracion_pagina.getOrden().impresion,this.usuario);
					}
				}
			}else{
				this.pdfService.imprimirVenta(ventaConsultada,this.configuracion_pagina.getOrden().impresion,false,this.usuario,this.tipo_textos);
				if(this.usuario.empresa.usar_inventario && this.configuracion_pagina.getOrden().impresion.nota_almacen){
					this.pdfService.imprimirNotaAlmacenCartaOficio(ventaConsultada,this.configuracion_pagina.getOrden().impresion,this.usuario);
				}
			}
		});*/
	}

	crearNuevoConsumo(){
		this.modalVenta = this.modalService.open(VentaServicioComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalVenta.componentInstance.venta.usuario = this.usuario;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
		this.modalVenta.componentInstance.inhabilitar_fecha = true;
		this.modalVenta.componentInstance.configuracion_pagina=this.configuracion_pagina;
		
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.imprimirVentaConsumoAgua(res.venta);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
	}

	crearNuevoVentaInstituto(){
		this.modalVenta = this.modalService.open(VentaEstudianteComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalVenta.componentInstance.venta.usuario = this.usuario;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
		this.modalVenta.componentInstance.inhabilitar_fecha = true;
		
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.imprimirVenta(res.venta,true);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
	}

	imprimirVentaConsumoAgua(venta){
		this.blockUI.start();
		this.ventasService.obtenerVenta(venta.id).subscribe((ventaConsultada:any) => {
			this.blockUI.stop();
			var fecha=new Date(ventaConsultada.fecha);
			ventaConsultada.fechaTexto=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
			if(this.configuracion_pagina.getOrden().impresion.al_guardar){
				this.pdfService.imprimirReciboConsumoAgua(ventaConsultada,this.configuracion_pagina.getOrden().impresion,false,this.usuario,this.tipo_textos);
			}
		});
	}

	abrirPopupConfirmacionEliminacion(venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular la venta?";
		this.popupConfirmacion.componentInstance.data = venta;
		this.popupConfirmacion.componentInstance.es_venta = true;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarVenta($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	eliminarVenta(venta){
		this.blockUI.start();
		venta.usar_facturacion_computarizada_en_linea_por_sucursal_empresa=this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa;
		this.ventasService.anularVenta(venta).subscribe((res:any) => {
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
				this.blockUI.stop();
			}else{
				this.toastr.success(res.mensaje);
				if(venta.pedido){
					this.pedidoService.inactivarPedido({id:venta.pedido}).subscribe((res:any) => {
						this.blockUI.stop();
						if(this.usuario.empresa.punto_venta_offline){
							this.sincronizarAnulacionVenta(venta);
						}
					});
				}else{
					if(this.usuario.empresa.punto_venta_offline){
						this.sincronizarAnulacionVenta(venta);
					}
					this.blockUI.stop();
				}
	
				if(this.usuario.empresa.usar_consumo_de_agua){
					this.blockUI.start();
					this.ventasService.anularServicio(venta.id).subscribe((res:any) => {
						this.toastr.info(res.mensaje);
						this.blockUI.stop();
					});
				}
	
				if(this.usuario.empresa.usar_ingresos_economicos_instituto || this.usuario.empresa.usar_ingresos_economicos_colegio){
					this.blockUI.start();
					this.ventasService.anularVentaEstudiante(venta.id).subscribe((res:any) => {
						this.toastr.info(res.mensaje);
						this.blockUI.stop();
					});
				}

				if(venta.transaccion_nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || venta.transaccion_nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL){
					this.enviarCorreoElectronicoAnulacion(venta);
				}

				this.getItems();
			}
		});
	}

	sincronizarAnulacionVenta(venta){
		this.ventasService.sincronizarAnulacionVenta({id_venta:venta.id}).subscribe((res:any) => {
			
		});
	}

	crearPagoVenta(venta){
		this.modalVenta = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalVenta.componentInstance.venta = venta;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.getItems();
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
	}

	verPagosVenta(venta){
		this.modalVenta = this.modalService.open(ListaPagosVentasComponent, {windowClass:'lista-pagos', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalVenta.componentInstance.venta = venta?venta:null;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
	}

	verVenta(venta){
		this.modalVenta = this.modalService.open(VistaVentaComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-venta', backdrop: 'static'});
		this.modalVenta.componentInstance.venta = venta;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			this.modalVenta.close();
		});
	}

	copiarVenta(venta_){
		this.blockUI.start();
		this.ventasService.obtenerVenta(venta_.id).subscribe((venta:any) => {
			venta.id=null;
			this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup' ,ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta=venta;
			this.modalVenta.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
			this.modalVenta.componentInstance.venta.usuario = this.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;	
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.modalVenta.componentInstance.nombre_corto_tipo_pago=venta.tipoPago.nombre_corto;
			this.modalVenta.componentInstance.nombre_corto_transaccion=venta.transaccion.nombre_corto;
			this.modalVenta.componentInstance.cantidad_decimal=this.configuracion_pagina.getOrden().cantidad_decimal;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
						this.toastr.success(res.mensaje);
						this.imprimirVenta(res.venta,true);
						this.modalVenta.close();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
		
		
			if(this.usuario.empresa.usar_pantalla_cliente){
				//socket.emit('comenzarVenta',$rootScope.venta);
			}
		});
	}

	verVentasBorrador(){
		this.ventas_borrador=this.persistenciaService.get("ventas")?this.persistenciaService.get("ventas"):[];
		this.ventas_borrador_modal=this.modalService.open(this.ventas_borrador_ref, {windowClass:"ventas-borrador",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.ventas_borrador_modal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	abrirPopupConfirmacionEliminacionBorrador(venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el borrador de la venta?";
		this.popupConfirmacion.componentInstance.data = venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarVentaBorrador($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	  eliminarVentaBorrador(ventaBorrador){
		this.blockUI.start();
		this.ventas_borrador.splice(this.ventas_borrador.indexOf(ventaBorrador),1);
		this.persistenciaService.set("ventas",this.ventas_borrador);
		this.toastr.info("Venta borrador eliminada satisfactoriamente!");
		this.blockUI.stop();
	}

	modificarVentaBorrador(venta_borrador){
		this.blockUI.start();
		this.ventas_borrador_modal.close();
		this.modalVenta = this.modalService.open(VentaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalVenta.componentInstance.configuracion_pagina=this.configuracion_pagina.object;
		this.modalVenta.componentInstance.venta=venta_borrador;
		this.modalVenta.componentInstance.venta.usuario = this.usuario;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;	
		this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
		this.modalVenta.componentInstance.nombre_corto_tipo_pago=venta_borrador.tipoPago.nombre_corto;
		this.modalVenta.componentInstance.nombre_corto_transaccion=venta_borrador.transaccion.nombre_corto;
		this.modalVenta.componentInstance.cantidad_decimal=this.configuracion_pagina.getOrden().cantidad_decimal;
		this.blockUI.stop();
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.imprimirVenta(res.venta,true);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
		});
	
	
		if(this.usuario.empresa.usar_pantalla_cliente){
			//socket.emit('comenzarVenta',$rootScope.venta);
		}
	}

	abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionVentasComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

	abrirPopupConfirmacionExtraccion(venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de aplicar el descuento de cantidades al almacen?";
		this.popupConfirmacion.componentInstance.data = venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.extraerInventario($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	extraerInventario(venta){
		this.blockUI.start();
		this.ventasService.descontarAlmacen(venta).subscribe((res:any) => {
			if(!res.tiene_error){
				this.getItems();
				this.toastr.success(res.mensaje);
			}else{
				this.toastr.error(res.mensaje);
			}
			this.blockUI.stop();
		});
	}

	abrirPopupConfirmacionEnvioCorreo(venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el documento de la venta por correo electrónico?";
		this.popupConfirmacion.componentInstance.data = venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.enviarCorreoElectronico($e.data,null);
			}
			this.popupConfirmacion.close();
        });
	  }

	async enviarCorreoElectronico(_venta,pdf_recibido){
		let venta:any=await this.ventasService.obtenerVenta(_venta.id).toPromise();
		if(venta.cliente_correo || venta.cliente.email){
			if(!venta.sin_evento || venta.sin_evento.evento.nombre_corto=="2"){
				let transaccion=venta.transaccion.nombre_corto?venta.transaccion.nombre_corto:venta.transaccion_nombre_corto;
				let id_sucursal=venta.sucursal.id?venta.sucursal.id:venta.id_sucursal;
				let cliente=venta.cliente.email?venta.cliente:{identificacion:venta.cliente,email:venta.cliente_correo?venta.cliente_correo:venta.cliente.email,razon_social:venta.razon_social};
				let me=this;
				var fecha=new Date(venta.fecha);
				var pdf=pdf_recibido?pdf_recibido:await this.pdfService.generarPdfDocumentoVenta(venta);
				var reader = new window.FileReader();
				reader.readAsDataURL(pdf);
				reader.onloadend = function () {
					let base64data = reader.result;
					let en={
						documento:base64data,
						xml:(transaccion==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || transaccion==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL)?(venta.sin_en_linea?'./files/empresa-'+me.usuario.id_empresa+'/facturas-online-'+id_sucursal+'/'+venta.cuf+'.xml':'./files/empresa-'+me.usuario.id_empresa+'/facturas-offline-'+id_sucursal+'/'+venta.cuf+'.xml'):null,
						nombre_xml:venta.cuf+".xml",
						cliente:cliente,
						nombre_documento:(transaccion==GlobalVariable.Dictionary.EGRE_FACTURACION || transaccion==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || transaccion==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL)?"Factura":"Nota de Venta",
						numero_documento:venta.factura,
						detalle_documento:venta.detallesVenta,
						fecha:fecha.getDate()+"-"+(fecha.getMonth()+1)+"-"+fecha.getFullYear(),
						nombre_empresa:me.usuario.empresa.nombre,
						telefono:me.usuario.empresa.telefono1
					};
					me.blockUI.start();
					me.ventasService.enviarDocumentoVentaCorreo(en).subscribe((res:any) => {
						if(res.tiene_error){
							me.toastr.error(res.mensaje);
						}else{
							me.toastr.success(res.mensaje);
						}
						me.blockUI.stop();
					});
				}
			}
		}else{
			this.toastr.error("Debe agregar una dirección de correo electrónico para el cliente");
		}
	}

	async enviarCorreoElectronicoAnulacion(venta){
		if(venta.cliente_correo || venta.cliente.email){
			let me=this;
			var fecha=new Date(venta.fecha);
			var pdf=await this.pdfService.generarPdfDocumentoVenta(venta);
			var reader = new window.FileReader();
			reader.readAsDataURL(pdf);
			reader.onloadend = function () {
				let base64data = reader.result;
				let en={
					id_venta:venta.id,
					documento:base64data,
					cuf:venta.cuf,
					cliente:{identificacion:venta.cliente,email:venta.cliente_correo?venta.cliente_correo:venta.cliente.email,razon_social:venta.razon_social},
					empresa:me.usuario.empresa,
					numero_documento:venta.factura,
					detalle_documento:venta.detallesVenta,
					fecha:fecha.getDate()+"-"+(fecha.getMonth()+1)+"-"+fecha.getFullYear()
				};
				me.blockUI.start();
				me.ventasService.enviarAnulacionVentaCorreo(en).subscribe((res:any) => {
					if(res.tiene_error){
						me.toastr.error(res.mensaje);
					}else{
						me.toastr.success(res.mensaje);
					}
					me.blockUI.stop();
				});
			}
		}else{
			this.toastr.error("Debe agregar una dirección de correo electrónico para el cliente");
		}
	}

	async generarPdfDocumentoVenta(venta){
		/*var pdf_respuesta=null;
		let ventaConsultada:any;
		if(!venta.detallesVenta || venta.detallesVenta.length==0){
			ventaConsultada=await this.ventasService.obtenerVenta(venta.id).toPromise();
			venta.detallesVenta=ventaConsultada.detallesVenta;
		}else{
			ventaConsultada=venta;
		}
		let fecha=new Date(ventaConsultada.fecha);
		ventaConsultada.fechaTexto=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
		pdf_respuesta=await this.pdfService.imprimirVenta(ventaConsultada,this.configuracion_pagina.getOrden().impresion,false,this.usuario,this.tipo_textos);
		return pdf_respuesta;*/
	}

	async generarPdfFiltroVentas(){
		this.blockUI.start();
		let fechaInicio=this.filter.fecha_inicio.day+"/"+(this.filter.fecha_inicio.month)+"/"+this.filter.fecha_inicio.year;
		let fechaFin=this.filter.fecha_fin.day+"/"+(this.filter.fecha_fin.month)+"/"+this.filter.fecha_fin.year;
		let ventas=this.ventas;
		if(this.datos_con_detalle){
			this.filter.con_detalle=true;
			let dato:any=await this.ventasService.obtenerListaVentas(this).toPromise();
			this.filter.con_detalle=false;
			ventas=this.procesarVentas(dato.ventas);
		}
		this.pdfService.generarPdfFiltroVentas(ventas,fechaInicio,fechaFin,this.datos_con_detalle,this.usuario);
		this.blockUI.stop();
	}

	async generarExcelFiltroVentas(){
		this.blockUI.start();
		let ventas=this.ventas;
		if(this.datos_con_detalle){
			this.filter.con_detalle=true;
			let dato:any=await this.ventasService.obtenerListaVentas(this).toPromise();
			this.filter.con_detalle=false;
			ventas=this.procesarVentas(dato.ventas);
		}

		var suma_importe=0,suma_total=0,suma_descuento=0;
		var data = [];
		var header=["N°"];
		if(this.configuracion_pagina.getConfiguration().transaccion.show){
			header.push("Tipo de Transaccion");
		}
		if(this.configuracion_pagina.getConfiguration().factura.show){
			header.push("Número Doc.");
		}
		if(this.configuracion_pagina.getConfiguration().sucursal.show){
			header.push("Sucursal");
		}
		if(this.configuracion_pagina.getConfiguration().cliente.show){
			header.push("Cliente");
		}
		if(this.configuracion_pagina.getConfiguration().razon_social.show){
			header.push("Razón social");
		}
		if(this.configuracion_pagina.getConfiguration().nit.show){
			header.push("Nit");
		}
		if(this.configuracion_pagina.getConfiguration().cliente_categoria.show){
			header.push("Categoria Cliente");
		}
		if(this.configuracion_pagina.getConfiguration().fecha.show){
			header.push("Fecha Doc.");
		}
		if(this.configuracion_pagina.getConfiguration().createdAt.show){
			header.push("Hora Fecha Registro");
		}
		if(this.configuracion_pagina.getConfiguration().fecha_ingreso_hotel.show && this.usuario.empresa.usar_hoteles){
			header.push("Fecha Ingreso Hotel");
		}
		if(this.configuracion_pagina.getConfiguration().fecha_salida_hotel.show && this.usuario.empresa.usar_hoteles){
			header.push("Fecha Salida Hotel");
		}
		if(this.configuracion_pagina.getConfiguration().importe.show){
			header.push("Importe");
		}
		if(this.configuracion_pagina.getConfiguration().descuento.show){
			header.push("Descuento");
		}
		if(this.configuracion_pagina.getConfiguration().total.show){
			header.push("Total");
		}
		if(this.configuracion_pagina.getConfiguration().cobrado.show){
			header.push("Cobrado");
		}
		if(this.configuracion_pagina.getConfiguration().saldo.show){
			header.push("Saldo");
		}
		if(this.configuracion_pagina.getConfiguration().dias_credito.show){
			header.push("Dias Credito");
		}
		if(this.configuracion_pagina.getConfiguration().dias_mora.show){
			header.push("Dias Mora");
		}
		if(this.configuracion_pagina.getConfiguration().tipo_pago.show){
			header.push("Tipo de Pago");
		}
		if(this.configuracion_pagina.getConfiguration().despacho.show){
			header.push("Despacho");
		}
		if(this.configuracion_pagina.getConfiguration().usuario.show){
			header.push("Usuario");
		}
		if(this.configuracion_pagina.getConfiguration().canal.show){
			header.push("Canal");
		}
		if(this.configuracion_pagina.getConfiguration().estado.show){
			header.push("Estado");
		}
		if(this.configuracion_pagina.getConfiguration().activa.show){
			header.push("Validez");
		}
		if(this.configuracion_pagina.getConfiguration().observacion.show){
			header.push("Observación");
		}

		header.push("Fecha Pago");
		header.push("Observación");

		data.push(header);
		/*var sumaImporte=0,sumaImporteNo=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;*/
		for(var i=0;i<ventas.length;i++){
			var columns=[];
			columns.push(i+1);

			if(this.configuracion_pagina.getConfiguration().transaccion.show){
				columns.push(ventas[i].transaccion);
			}
			if(this.configuracion_pagina.getConfiguration().factura.show){
				columns.push(ventas[i].factura);
			}
			if(this.configuracion_pagina.getConfiguration().sucursal.show){
				columns.push(ventas[i].sucursal);
			}
			if(this.configuracion_pagina.getConfiguration().cliente.show){
				columns.push(ventas[i].cliente);
			}
			if(this.configuracion_pagina.getConfiguration().razon_social.show){
				columns.push(ventas[i].razon_social);
			}
			if(this.configuracion_pagina.getConfiguration().nit.show){
				columns.push(ventas[i].nit_ci);
			}
			if(this.configuracion_pagina.getConfiguration().cliente_categoria.show){
				columns.push(ventas[i].categoria_cliente?ventas[i].categoria_cliente:"");
			}
			if(this.configuracion_pagina.getConfiguration().fecha.show){
				ventas[i].fecha=new Date(ventas[i].fecha);
				columns.push(ventas[i].fecha.getDate()+"/"+(ventas[i].fecha.getMonth()+1)+"/"+ventas[i].fecha.getFullYear());
			}
			if(this.configuracion_pagina.getConfiguration().createdAt.show){
				ventas[i].createdAt=new Date(ventas[i].createdAt);
				columns.push(ventas[i].createdAt.getHours()+":"+ventas[i].createdAt.getMinutes()+"-"+ventas[i].createdAt.getDate()+"/"+(ventas[i].createdAt.getMonth()+1)+"/"+ventas[i].createdAt.getFullYear());
			}
			if(this.configuracion_pagina.getConfiguration().fecha_ingreso_hotel.show && this.usuario.empresa.usar_hoteles){
				ventas[i].fecha_ingreso_hotel=new Date(ventas[i].fecha_ingreso_hotel);
				columns.push(ventas[i].fecha_ingreso_hotel.getDate()+"/"+(ventas[i].fecha_ingreso_hotel.getMonth()+1)+"/"+ventas[i].fecha_ingreso_hotel.getFullYear()+"-"+ventas[i].fecha_ingreso_hotel.getHours()+":"+ventas[i].fecha_ingreso_hotel.getMinutes());
			}
			if(this.configuracion_pagina.getConfiguration().fecha_salida_hotel.show && this.usuario.empresa.usar_hoteles){
				ventas[i].fecha_salida_hotel=new Date(ventas[i].fecha_salida_hotel);
				columns.push(ventas[i].fecha_salida_hotel.getDate()+"/"+(ventas[i].fecha_salida_hotel.getMonth()+1)+"/"+ventas[i].fecha_salida_hotel.getFullYear()+"-"+ventas[i].fecha_salida_hotel.getHours()+":"+ventas[i].fecha_salida_hotel.getMinutes());
			}
			if(this.configuracion_pagina.getConfiguration().importe.show){
				columns.push(ventas[i].importe);
			}
			if(this.configuracion_pagina.getConfiguration().descuento.show){
				columns.push(ventas[i].descuento);
			}
			if(this.configuracion_pagina.getConfiguration().total.show){
				columns.push(ventas[i].total);
			}
			if(this.configuracion_pagina.getConfiguration().cobrado.show){
				columns.push(ventas[i].a_cuenta);
			}
			if(this.configuracion_pagina.getConfiguration().saldo.show){
				columns.push(ventas[i].saldo);
			}
			if(this.configuracion_pagina.getConfiguration().dias_credito.show){
				columns.push(ventas[i].dias_credito);
			}
			if(this.configuracion_pagina.getConfiguration().dias_mora.show){
				columns.push(this.calcularDiasMora(ventas[i]));
			}
			if(this.configuracion_pagina.getConfiguration().tipo_pago.show){
				columns.push(ventas[i].tipo_pago);
			}
			if(this.configuracion_pagina.getConfiguration().despacho.show){
				columns.push(ventas[i].tipo_despacho);
			}
			if(this.configuracion_pagina.getConfiguration().usuario.show){
				columns.push(ventas[i].usuario);
			}
			if(this.configuracion_pagina.getConfiguration().canal.show){
				columns.push(ventas[i].canal?ventas[i].canal:"");
			}
			if(this.configuracion_pagina.getConfiguration().estado.show){
				columns.push(ventas[i].estado?ventas[i].estado:"");
			}
			if(this.configuracion_pagina.getConfiguration().activa.show){
				columns.push(ventas[i].activa?"ACTIVO":"ANULADO");
			}
			if(this.configuracion_pagina.getConfiguration().observacion.show){
				columns.push(ventas[i].observacion);
			}

			var fechas_pago="",observaciones="";
			for(var j=0; ventas[i].pagosVenta && j<ventas[i].pagosVenta.length;j++){
				var fecha_pago=new Date(ventas[i].pagosVenta[j].createdAt);
				fechas_pago=fechas_pago+fecha_pago.getDate()+"/"+(fecha_pago.getMonth()+1)+"/"+fecha_pago.getFullYear();
				observaciones=observaciones+ventas[i].pagosVenta[j].observacion;
			}
			columns.push(ventas[i].fechas_pago);
			columns.push(ventas[i].observaciones);

			if(ventas[i].activa){
				suma_importe=suma_importe+ventas[i].importe;
				suma_descuento=suma_descuento+ventas[i].descuento;
				suma_total=suma_total+ventas[i].total;
			}

			data.push(columns);
			if(this.datos_con_detalle){
				data.push(["","","","","N°","Nombre","Codigo Item","Unidad de Med","Cantidad","Importe","Descuento","Total"]);
				for(var j=0; j<ventas[i].detallesVenta.length;j++){
					columns=[];
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push(j+1);
					columns.push(ventas[i].detallesVenta[j].producto?ventas[i].detallesVenta[j].producto:ventas[i].detallesVenta[j].descripcion?ventas[i].detallesVenta[j].descripcion:"");
					columns.push(ventas[i].detallesVenta[j].producto?ventas[i].detallesVenta[j].codigo_producto:"");
					columns.push(ventas[i].detallesVenta[j].producto?ventas[i].detallesVenta[j].unidad_medida:"UND");
					columns.push(ventas[i].detallesVenta[j].cantidad);
					columns.push(ventas[i].detallesVenta[j].importe_detalle);
					columns.push(ventas[i].detallesVenta[j].descuento_detalle);
					columns.push(ventas[i].detallesVenta[j].total_detalle);
					
					data.push(columns);
					}
			}
			if(i+1==this.ventas.length){
				columns=[];
				columns.push("");
				if(this.configuracion_pagina.getConfiguration().transaccion.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().factura.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().sucursal.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().cliente.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().razon_social.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().nit.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().cliente_categoria.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().fecha.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().createdAt.show){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().fecha_ingreso_hotel.show && this.usuario.empresa.usar_hoteles){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().fecha_salida_hotel.show && this.usuario.empresa.usar_hoteles){
					columns.push("");
				}
				if(this.configuracion_pagina.getConfiguration().importe.show){
					columns.push(suma_importe);
				}
				if(this.configuracion_pagina.getConfiguration().descuento.show){
					columns.push(suma_descuento);
				}
				if(this.configuracion_pagina.getConfiguration().total.show){
					columns.push(suma_total);
				}
			
				data.push(columns);
			}
			
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("VENTAS");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 20;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "Reporte-ventas.xlsx");
		})
		this.blockUI.stop();
	}

	subirVentasApp(event){
		//$scope.obtenerUsuarios(null);
		this.importacion.sucursal=this.filter.sucursales[1];
		this.obtenerAlmacenesImportacion(this.importacion.sucursal.id);
		this.importacion.descontar_almacen=this.usuario.empresa.usar_inventario;
		this.importacion.usuario=this.filter.usuarios.filter( e => e.activo)[0];
		var files = event.target.files;
		var i,f;
		this.mensaje_importacion=null;
		let imp:any=this;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			var reader = new FileReader();
			var name = f.name;
			reader.onload = function(e:any) {
				try{
					imp.ventas_importacion = JSON.parse(e.target.result).ventas;
				}catch(error){
					imp.mensaje_importacion="Asegurese de llenar los datos en el primera hoja, "+error;
				}
			};
			reader.readAsBinaryString(f);
		}

		this.abrirModalImportacionVentas();
	}

	abrirModalImportacionVentas(){
		this.importacion_ventas_modal=this.modalService.open(this.importacion_ventas_ref, {windowClass:'ventas-importacion', ariaLabelledBy: 'modal-basic-title', size:'lg', backdrop: 'static'});
		this.importacion_ventas_modal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	subirPagosVentasApp(event){
		//$scope.obtenerUsuarios(null);
		var files = event.target.files;
		var i,f;
		this.mensaje_importacion=null;
		let imp:any=this;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			var reader = new FileReader();
			var name = f.name;
			reader.onload = function(e:any) {
				try{
					imp.pagos_ventas_importacion = JSON.parse(e.target.result).subida_cuentas_clientes;
				}catch(error){
					imp.mensaje_importacion="Asegurese de llenar los datos en el primera hoja, "+error;
				}
			};
			reader.readAsBinaryString(f);
		}

		this.abrirModalImportacionPagosVentas();
	}

	abrirModalImportacionPagosVentas(){
		this.importacion_pagos_ventas_modal=this.modalService.open(this.importacion_pagos_ventas_modal_ref, {windowClass:'ventas-importacion', ariaLabelledBy: 'modal-basic-title', size:'lg', backdrop: 'static'});
		this.importacion_pagos_ventas_modal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	guardarImportacionVentas(){
		this.blockUI.start();
		for(let i=0;i<this.ventas_importacion.length;i++){
			//this.ventas_importacion[i].descontar_almacen=this.importacion.descontar_almacen;
		}
		this.ventasService.importarVentas({ventas:this.ventas_importacion,id_almacen:this.importacion.almacen.id,id_empresa:this.usuario.id_empresa,id_usuario:this.importacion.usuario.id,id_sucursal:this.importacion.sucursal.id,descontar_almacen:this.importacion.descontar_almacen}).subscribe((res:any)=>{
			this.blockUI.stop();
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.importacion_ventas_modal.close();
			}
		});
	}

	async guardarImportacionPagosVentas(){
		this.blockUI.start();
		for(let i=0;i<this.pagos_ventas_importacion.length;i++){
			await this.ventasService.guardarPagoVenta(this.pagos_ventas_importacion[i].venta.id,this.usuario.id_empresa,
				{
					id_usuario_cajero:this.pagos_ventas_importacion[i].id_usuario_cajero,
					monto_pagado:this.pagos_ventas_importacion[i].monto_pagado,
					fecha:new Date()
				}).toPromise();
		}
		this.importacion_pagos_ventas_modal.close();
		this.toastr.success("Importacion de pagos realizado satisfactoriamente!");
		this.blockUI.stop();
	}

	eliminarVentaImportacion(venta){
		this.ventas_importacion.splice(this.ventas_importacion.indexOf(venta),1);
	}

	eliminarPagoVentaImportacion(pago_venta){
		this.pagos_ventas_importacion.splice(this.pagos_ventas_importacion.indexOf(pago_venta),1);
	}

	obtenerAlmacenesImportacion(idSucursal){
		this.almacenes=[];
		var sucursal=this.filter.sucursales.filter(e => e.id == idSucursal)[0];
		this.almacenes=sucursal.almacenes;
		this.importacion.sucursal=sucursal;
		this.importacion.almacen=this.almacenes.length>0?this.almacenes[0]:null;
	}

	generarExcelVentasGrupos(){
		this.blockUI.start();
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").subscribe((tipoGrupoEmpresa:any) => {
			let grupos=tipoGrupoEmpresa.clases;
			var suma_importe=0;
			var data = [];
			let fecha_inicial=this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year;
			let fecha_final=this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year;
			let cabecera=["N°"];
			cabecera.push("Venta de productos por grupos");
			cabecera.push("Del "+fecha_inicial+" Al "+fecha_final);
			
			data.push(cabecera);
			for(let k=0;k<grupos.length;k++){
				grupos[k].detalles_ventas=[];
				grupos[k].productos=[];
				for(let i=0;i<this.ventas.length;i++){
					if(this.ventas[i].activa){
						grupos[k].detalles_ventas=grupos[k].detalles_ventas.concat(this.ventas[i].detallesVenta.filter(e => e.producto.id_grupo==grupos[k].id));
					}
				}
				console.log(grupos[k].detalles_ventas);
				let id_productos = grupos[k].detalles_ventas.filter((detalle_venta, i, arr) => arr.findIndex(t => t.id_producto === detalle_venta.id_producto) === i);
				console.log(id_productos);
				for(let i=0;i<id_productos.length;i++){
					let detalles_venta_producto=grupos[k].detalles_ventas.filter(e => e.producto.id==id_productos[i].producto.id);
					let producto:any={ cantidad:0 };
					for(let z=0;z<detalles_venta_producto.length;z++){
						producto.nombre=detalles_venta_producto[z].producto.nombre;
						producto.precio_unitario=detalles_venta_producto[z].precio_unitario;
						producto.cantidad=producto.cantidad+detalles_venta_producto[z].cantidad;
					}
					producto.total_importe=producto.cantidad*producto.precio_unitario;
					grupos[k].productos.push(producto);

				}
			}

			data.push(["","GRUPO","PRODUCTO","PRECIO UNITARIO","CANTIDAD","TOTAL"]);
			for(let i=0;i<grupos.length;i++){
				if(grupos[i].productos.length>0){
					data.push(["",grupos[i].nombre]);
					for(let j=0;j<grupos[i].productos.length;j++){
						data.push(["","",grupos[i].productos[j].nombre,grupos[i].productos[j].precio_unitario,grupos[i].productos[j].cantidad,grupos[i].productos[j].total_importe]);
					}
				}
			}
			

			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet("VENTAS");
			data.forEach(d => {
			let row = worksheet.addRow(d);
			}
			);

			const dobCol2 = worksheet.getColumn(2);
			dobCol2.width = 40;
			const dobCol3 = worksheet.getColumn(3);
			dobCol3.width = 20;

			workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "Reporte-ventas-grupos.xlsx");
			})
			this.blockUI.stop();
		});
	}

	abrirPopupConfirmacionEliminacionVentaImportacion(venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.data = venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarVentaImportacion($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	  abrirPopupConfirmacionEliminacionPagoVentaImportacion(pago_venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.data = pago_venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarPagoVentaImportacion($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	verCotizaciones(){
		this.blockUI.start();
		this.cotizacionesService.obtenerCotizacionesSucursal(Util.obtenerIdsSucursales(this.usuario)).subscribe((cotizaciones:any[])=>{
			this.cotizaciones=cotizaciones;
			this.blockUI.stop();
			this.lista_cotizaciones_modal=this.modalService.open(this.lista_cotizaciones_modal_ref, {windowClass:'ventas-importacion', ariaLabelledBy: 'modal-basic-title', size:'lg', backdrop: 'static'});
			this.lista_cotizaciones_modal.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
	   	});
	}

	usarCotizacion(cotizacion){
		this.lista_cotizaciones_modal.close();
		this.blockUI.start();
		cotizacion.fecha=new Date(cotizacion.fecha);
		var fechaTexto=cotizacion.fecha.getDate()+"/"+(cotizacion.fecha.getMonth()+1)+"/"+cotizacion.fecha.getFullYear();
		this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup' ,ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalVenta.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
		this.modalVenta.componentInstance.venta.cotizacion=cotizacion;
		this.modalVenta.componentInstance.venta.observacion=cotizacion.descripcion;
		this.modalVenta.componentInstance.venta.detallesVenta=cotizacion.detallesCotizacion;
		this.modalVenta.componentInstance.venta.pagado=cotizacion.total;
		this.modalVenta.componentInstance.venta.descuento=cotizacion.descuento;
		this.modalVenta.componentInstance.venta.tipo_descuento=cotizacion.tipo_descuento;
		this.modalVenta.componentInstance.venta.importe=cotizacion.importe;
		this.modalVenta.componentInstance.venta.cliente=cotizacion.cliente;
		this.modalVenta.componentInstance.venta.usuario = this.usuario;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;	
		this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
		this.modalVenta.componentInstance.cantidad_decimal=this.configuracion_pagina.getOrden().cantidad_decimal;
		this.blockUI.stop();
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.imprimirVenta(res.venta,true);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
	}

	abrirPopupConfirmacionEliminacionCotizacion(cotizacion){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de quitar la cotizacion?";
		this.popupConfirmacion.componentInstance.data = cotizacion;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCotizacion($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	eliminarCotizacion(cotizacion){
		this.blockUI.start();
		this.cotizacionesService.anularCotizacion(cotizacion).subscribe((res:any)=>{
			this.blockUI.stop();
			this.lista_cotizaciones_modal.close();
			this.toastr.success(res.mensaje);
		});
	}

	verReportePagosEstudiantesInstituto(){
		this.reporte_pagos_instituto = this.modalService.open(ReportePagosInstitutoComponent, {windowClass:'ventas-importacion', ariaLabelledBy: 'modal-basic-title', size:'md', backdrop: 'static'});
		this.reporte_pagos_instituto.componentInstance.usuario = this.usuario;
		
		this.reporte_pagos_instituto.componentInstance.onConfirm.subscribe(($e) => {
			this.reporte_pagos_instituto.close();
        });
	}

	abrirPopupConfirmacionEnvioWhatsapp(venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el documento de la venta por Whatsapp?";
		this.popupConfirmacion.componentInstance.data = venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.enviarVentaWhatsapp($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	async enviarVentaWhatsapp(_venta){
		let venta:any=await this.ventasService.obtenerVenta(_venta.id).toPromise();
		let mensaje_empresa:any=await this.mensajesEmpresaService.obtenerMensajeEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.MKT.MEN_ENVIO_RECIBO_FACTURA).toPromise();
		if(venta.cliente.telefono1 || venta.cliente.persona.telefono_movil){
			let me=this;
			let fecha=new Date(venta.fecha);
			let detalles="";
			let documento=((venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA)?"Factura":this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_RECIBO_NOTA_VENTA));
			for(var i=0;i<venta.detallesVenta.length;i++){
				var detalle=venta.detallesVenta[i].producto?venta.detallesVenta[i].producto.nombre+(venta.detallesVenta[i].descripcion?" - "+venta.detallesVenta[i].descripcion:""):venta.detallesVenta[i].descripcion;
				detalles=detalles+" ✅ *"+detalle+"* \n";
			}
			let mensaje=mensaje_empresa.plantilla_mensaje.mensaje;
			mensaje=mensaje.replace("[[nombre_cliente]]",venta.cliente.identificacion);
			mensaje=mensaje.replace("[[nombre_documento]]",documento);
			mensaje=mensaje.replace("[[fecha_documento]]",(fecha.getDate()+"-"+(fecha.getMonth()+1)+"-"+fecha.getFullYear()));
			mensaje=mensaje.replace("[[detalles]]",detalles);
			mensaje=mensaje.replace("[[total]]",venta.total.toFixed(2));
			let en:any={
				telefono_whatsapp:venta.cliente.telefono1?venta.cliente.telefono1:venta.cliente.persona.telefono_movil,
				mensaje:mensaje,
				sucursal:venta.sucursal,
				nombre_documento:documento,
				tipo_pago:venta.tipoPago.nombre_corto
			};
			me.blockUI.start();
			var pdf=await this.pdfService.generarPdfDocumentoVenta(venta);
			var reader = new window.FileReader();
			reader.readAsDataURL(pdf);
			reader.onloadend = function () {
				let base64data = reader.result;
				en.documento=base64data;
				me.ventasService.enviarVentaWhatsapp(en).subscribe((r=>{}));
				//me.socket.emit('enviarVentaWhatsapp',en);
				me.blockUI.stop();
			}
		}else{
			this.toastr.error("Debe agregar un telefono para el cliente");
		}
	}

	verDevolucionesVentas(venta){
		this.modalVenta = this.modalService.open(ListaDevolucionesVentasComponent, {windowClass:'lista-devoluciones', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalVenta.componentInstance.venta = venta?venta:null;
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
	}

	verificarEstadoFacturaSin(venta){
		this.blockUI.start();
		this.ventasService.verificarEstadoFacturaSin(venta.id).subscribe((res:any)=>{
			if(res.tiene_error){
				this.toastr.warning(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
			}
			this.blockUI.stop();
		});
	}

	verEventosSignificativos(){
		this.modalVenta = this.modalService.open(SinListaEventosSignificativosComponent, {windowClass:'lista-eventos-significativos', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalVenta.componentInstance.usuario = this.usuario;
		this.modalVenta.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
	}

	generarExcelReportePagosCuotasColegio(){
		this.blockUI.start();
		this.ventasService.obtenerDatosPagosEstudiantes(this).subscribe((datos:any) => {
			let estudiantes=datos;
			var data = [];
			let fecha_inicial=this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year;
			let fecha_final=this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year;
			let cabecera=[];
			cabecera.push("N°");
			cabecera.push("Código");
			cabecera.push("Apellido Paterno");
			cabecera.push("Apellido Materno");
			cabecera.push("Nombres");
			cabecera.push("Curso");
			cabecera.push("Consolidado");
			cabecera.push("Fecha Ultimo Pago");
			cabecera.push("Factura");
			cabecera.push("Concepto");
			cabecera.push("Importe");
			cabecera.push("Cuotas");
			cabecera.push("Cuotas Pagadas");
			cabecera.push("Cuotas Por Pagar");

			data.push(["Reporte Pagos Cuotas Estudiantes",null,null,"Del "+fecha_inicial+" Al "+fecha_final]);
			data.push(cabecera);
			for(let k=0;k<estudiantes.length;k++){
				let datos=[];
				datos.push(k+1);
				datos.push(estudiantes[k].codigo);
				datos.push(estudiantes[k].apellido_paterno);
				datos.push(estudiantes[k].apellido_materno);
				datos.push(estudiantes[k].nombres);
				datos.push(estudiantes[k].curso);
				datos.push(estudiantes[k].consolidado==1?"SÍ":"NO");

				estudiantes[k].fecha_ultimo_pago=new Date(estudiantes[k].fecha_ultimo_pago);
				datos.push(estudiantes[k].fecha_ultimo_pago);
				datos.push(estudiantes[k].factura);
				
				datos.push(estudiantes[k].concepto);
				datos.push(estudiantes[k].importe);
				datos.push(estudiantes[k].cuotas);
				datos.push(estudiantes[k].cuotas_pagadas);
				datos.push(estudiantes[k].cuotas_por_pagar);
				data.push(datos);
			}
			

			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet("Reporte Pagos Cuotas Estudiantes");
			data.forEach(d => {
			let row = worksheet.addRow(d);
			}
			);

			const dobCol1 = worksheet.getColumn(1);
			dobCol1.width = 5;
			const dobCol2 = worksheet.getColumn(2);
			dobCol2.width = 10;
			const dobCol3 = worksheet.getColumn(3);
			dobCol3.width = 20;
			const dobCol4 = worksheet.getColumn(4);
			dobCol4.width = 20;
			const dobCol5 = worksheet.getColumn(5);
			dobCol5.width = 20;
			const dobCol6 = worksheet.getColumn(6);
			dobCol6.width = 30;
			const dobCol8 = worksheet.getColumn(8);
			dobCol8.width = 20;
			const dobCol10 = worksheet.getColumn(10);
			dobCol10.width = 40;


			workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "Reporte Pagos Cuotas Estudiantes.xlsx");
			})
			this.blockUI.stop();
		});
	}

	convertirVentaCredito(venta){
		this.blockUI.start();
		this.venta=venta;
		this.venta.dias_credito=1;
		this.generalService.obtenerClases("ESTVENT").subscribe((entidad:any) => {
			this.estados=entidad.clases;
			this.blockUI.stop();
			this.conversion_venta_credito_modal=this.modalService.open(this.conversion_venta_credito_ref, {ariaLabelledBy: 'modal-basic-title', size:'sm', backdrop: 'static'});
			this.conversion_venta_credito_modal.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		  });
	}

	guardarVentaCredito(){
		if(this.venta.dias_credito!=null){
			this.venta.id_empresa=this.usuario.id_empresa;
			this.venta.estado=this.estados.filter(e => e.nombre_corto == "ESTVENTMOR")[0];
			this.venta.tipoPago=this.filter.tipos_pago.filter( t => t.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE)[0];
			this.blockUI.start();
			this.ventasService.convertirVentaCredito(this.venta).subscribe((res:any)=>{
				this.toastr.success(res.mensaje);
				this.conversion_venta_credito_modal.close();
				this.getItems();
				this.blockUI.stop();
			});
		}else{
			this.toastr.error("Debe especificar los dias de credito de la venta!");
		}
	}

	activarFacturacionOffline(){
		this.blockUI.start();
		let id_sucursal;
		if(this.usuario.empresa.usar_sucursales){
			if(this.filter.sucursal.id==0){
				this.toastr.error("Debe seleccionar una sucursal en el filtro!");
				return;
			}else{
				id_sucursal=this.filter.sucursal.id;
			}
		}else{
			id_sucursal=this.filter.sucursales[1].id;
		}
		this.ventasService.activarEventoSignificativo({id_sucursal:id_sucursal}).subscribe((res:any) => {
			this.blockUI.stop();
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
			}
		});
	}

	activarFacturacionOnline(){
		this.blockUI.start();
		let id_sucursal;
		if(this.usuario.empresa.usar_sucursales){
			if(this.filter.sucursal.id==0){
				this.toastr.error("Debe seleccionar una sucursal en el filtro!");
				return;
			}else{
				id_sucursal=this.filter.sucursal.id;
			}
		}else{
			id_sucursal=this.filter.sucursales[1].id;
		}
		this.ventasService.desactivarEventoSignificativo({id_sucursal:id_sucursal}).subscribe((res:any) => {
			this.blockUI.stop();
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
			}
		});
	}

	abrirParticipantesGrupoWhatsapp(){
		this.participantes_grupo_whatsapp_modal = this.modalService.open(ParticipantesGruposWhatsappComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.participantes_grupo_whatsapp_modal.componentInstance.usuario = this.usuario;
		
		this.participantes_grupo_whatsapp_modal.componentInstance.alTerminar.subscribe((res) => {
		  this.participantes_grupo_whatsapp_modal.close();
		});
	}

	anularFacturaCuf(){
		this.anulacion_cuf={
			sucursales:Util.obtenerSucursalesUsuario(this.usuario),
		};
		this.anulacion_cuf.sucursal=this.anulacion_cuf.sucursales[0]
		this.obtenerMotivosAnulacionSin();
		this.anulacion_factura_cuf_modal=this.modalService.open(this.anulacion_factura_cuf_modal_ref, {ariaLabelledBy: 'modal-basic-title', size:'md', backdrop: 'static'});
		this.anulacion_factura_cuf_modal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	obtenerMotivosAnulacionSin(){
		this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.SIN_MOTIVOS_ANULACION).subscribe((res:any)=>{
		  this.sin_motivos_anulacion=res.clases;
		  this.anulacion_cuf.motivo_anulacion=this.sin_motivos_anulacion[0];
		});
	}

	guardarAnulacionCuf(){
		if(this.anulacion_cuf.cuf){
			this.blockUI.start();
			this.ventasService.anularFacturaCuf(this.anulacion_cuf).subscribe((res:any)=>{
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
				}else{
					this.toastr.success(res.mensaje);
					this.anulacion_factura_cuf_modal.close();
				}
				this.blockUI.stop();
			});
		}else{
			this.toastr.error("Debe especificar el Código Único de Facturación!");
		}
	}

	descargarXmlFactura(venta){
		Util.descargarDocumento(venta.cuf,GlobalVariable.SERVER_URL+"files/empresa-"+this.usuario.empresa.id+"/facturas-online-"+venta.id_sucursal+"/"+venta.cuf+".xml");
	}
}
